import { Injectable, inject } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard extends KeycloakAuthGuard {
    protected override readonly router: Router;
    protected readonly keycloak: KeycloakService;

    constructor() {
        const router = inject(Router);
        const keycloak = inject(KeycloakService);

        super(router, keycloak);
        this.router = router;
        this.keycloak = keycloak;
    }

    public async isAccessAllowed(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ) {
        console.log('isAccessAllowed');
        // Force the user to log in if currently unauthenticated.
        if (!this.authenticated) {
            console.log('isAccessAllowed - NOT AUTHENTICATED');
            await this.keycloak.login({
                redirectUri: window.location.origin + state.url,
            });
        }

        // Get the roles required from the route.
        const requiredRoles = route.data['roles'];
        console.log('isAccessAllowed - ROLES', requiredRoles);

        // Allow the user to proceed if no additional roles are required to access the route.
        if (!Array.isArray(requiredRoles) || requiredRoles.length === 0) {
            return true;
        }

        console.log(this.roles);
        // const tokenParsed: Keycloak.KeycloakTokenParsed | undefined = this.keycloak.getKeycloakInstance().tokenParsed;
        // console.log(tokenParsed);

        // Allow the user to proceed if all the required roles are present.
        const allowed = requiredRoles.every((role) =>
            this.roles.includes(role)
        );
        console.log(`Allowed to access = ${allowed}`);
        return allowed;
    }
}
