<div class="header-container">
    <div class="title-container">
        <span class="title">{{ title }}</span>

        @if (!isProdEnvironment()) {
            <div class="project-environment">
                <span
                    id="runtimeEnv"
                    matTooltip="You are NOT running on the production system"
                    >{{ getRuntimeEnv() | uppercase }}</span
                >
            </div>
        }
    </div>

    <div class="logo-container">
        <img src="logos/sita-logo.png" alt="sita" />
    </div>

    @if (isLoggedIn) {
        <div class="profile">
            <div class="name">
                <span>
                    {{
                        (
                            userProfile?.firstName?.charAt(0) +
                            '' +
                            userProfile?.lastName?.charAt(0)
                        ).toUpperCase()
                    }}
                </span>
            </div>

            <div class="info">
                <p>
                    {{ userProfile?.email }}

                    @if (selectedTenant) {
                        (<a style="cursor: pointer" (click)="selectCompany()">{{
                            selectedTenant
                        }}</a
                        >)
                    }
                </p>

                <span class="logout" (click)="logout()">Logout</span>
            </div>
        </div>
    }
</div>
