export class UserStateModel {
    accessToken = '';
    username = '';
    fullName = '';
    role = '';
    isAdmin = false;
    photoURL = '';
    providerId = '';
    phoneNumber = '';
    email = '';
    displayName = '';
    base64Image = '';
    additionalUserInfo = {};

    constructor(props?: UserStateModel) {
        this.accessToken = props?.accessToken || '';
        this.username = props?.username || '';
        this.fullName = props?.fullName || '';
        this.role = props?.role || '';
        this.isAdmin = props?.isAdmin || false;
        this.photoURL = props?.photoURL || '';
        this.providerId = props?.providerId || '';
        this.phoneNumber = props?.phoneNumber || '';
        this.email = props?.email || '';
        this.displayName = props?.displayName || '';
        this.base64Image = props?.base64Image || '';
        this.additionalUserInfo = props?.additionalUserInfo || {};
    }
}

export class ProviderInfoModel {
    MICROSOFT = 'microsoft.com';
    GOOGLE = 'google.com';
    PHONE = 'phone';
}
