// DO NOT EDIT THIS FILE
// This file is auto generated during the build & deployment process
// see npm run generate-git-version
export const gitVersion = {
    name: 'opscopilotui',
    version: '0.0.2',
    buildNumber: '1',
    commit: '7bd405e',
    commitLong: '7bd405ea354fbe01442a7a41c60eecd35e804f71',
    branch: 'Detached: 7bd405ea354fbe01442a7a41c60eecd35e804f71',
    buildTimestamp: '2024-10-14T07:00:48UTC',
};
