import { Action, createReducer, on } from '@ngrx/store';
import { clearLoginState, setUserInformation } from '../actions';
import { cloneDeep } from 'lodash-es';
import { UserStateModel } from '@app/models/store/user';

const initialState: UserStateModel = new UserStateModel();

const userReducer = createReducer(
    initialState,
    on(setUserInformation, (state, action) => {
        const _u = cloneDeep(state);

        return _u;
    }),
    on(clearLoginState, () => {
        return initialState;
    }),
);

export default function reducer(
    state: UserStateModel,
    action: Action,
): UserStateModel {
    return userReducer(state, action);
}
