import { Component, inject, OnInit } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { LocalStorageConstants } from '@app/constants/local-storage-constants';
import { KeycloakTokenParsed } from 'keycloak-js';
import { DialogModule } from '@angular/cdk/dialog';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';

import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef } from '@angular/material/dialog';

const JWT_FIELD_TENNANTS = 'companies';

@Component({
    selector: 'app-select-company',
    templateUrl: './select-company-dialog.component.html',
    styleUrl: './select-company-dialog.component.scss',
    standalone: true,
    imports: [DialogModule, MatCardModule, MatListModule, MatButtonModule],
})
export class SelectCompanyDialog implements OnInit {
    protected readonly keycloak = inject(KeycloakService);

    private selectedTenant?: string | null;
    allowedTenants?: string[];
    private tokenParsed?: KeycloakTokenParsed;
    readonly dialogRef = inject(MatDialogRef<SelectCompanyDialog>);

    async ngOnInit(): Promise<void> {
        console.log('SelectCompanyDialog.ngOnInit');
        this.tokenParsed = this.keycloak.getKeycloakInstance().tokenParsed;
        // const userProfile = await this.userService.loadUserProfile();
        if (!this.tokenParsed) {
            // this.keycloak.getKeycloakInstance().tokenParsed
            console.log('reloading profile');
            await this.keycloak.getKeycloakInstance().loadUserInfo();
            this.tokenParsed = this.keycloak.getKeycloakInstance().tokenParsed;
        }
        console.log(this.tokenParsed);
        this.selectedTenant = localStorage.getItem(
            LocalStorageConstants.SELECTED_TENANT,
        );
        if (this.tokenParsed) {
            this.allowedTenants = this.tokenParsed[JWT_FIELD_TENNANTS];
        }
        console.log(this.allowedTenants);
    }

    cancel() {
        this.dialogRef.close(undefined);
    }

    select(selectedCompany: string) {
        this.dialogRef.close(selectedCompany);
    }
}
