import { SelectCompanyDialog } from '../select-company/select-company-dialog.component';
import { LocalStorageConstants } from '@app/constants/local-storage-constants';
import { GlobalVars, RuntimeEnvironment } from '@app/services/config.service';
import { DataShareService } from '@app/services/data-share.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { UserService } from '@app/services/user.service';
import { MatIconModule } from '@angular/material/icon';
import { KeycloakService } from 'keycloak-angular';
import { Component, OnInit, inject } from '@angular/core';
import { KeycloakProfile } from 'keycloak-js';
import { MatDialog } from '@angular/material/dialog';
import { CommonModule, NgStyle } from '@angular/common';

@Component({
    selector: 'app-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrl: './toolbar.component.scss',
    standalone: true,
    imports: [
        MatToolbarModule,
        MatTooltipModule,
        MatButtonModule,
        MatIconModule,
        CommonModule,
        NgStyle,
    ],
})
export class ToolbarComponent implements OnInit {
    userService = inject(UserService);
    keycloakService = inject(KeycloakService);
    matDialog = inject(MatDialog);
    dataShareService = inject(DataShareService);

    public userProfile: KeycloakProfile | null = null;
    public selectedTenant?: string | null = null;
    public isLoggedIn = false;
    title = 'Operations CoPilot';

    constructor() {
        this.selectedTenant = localStorage.getItem(
            LocalStorageConstants.SELECTED_TENANT,
        );
        this.title =
            this.selectedTenant === 'AK-CCD'
                ? 'Avatar Crew Chat'
                : 'Operations CoPilot';
    }

    async ngOnInit(): Promise<void> {
        this.isLoggedIn = await this.userService.isLoggedIn();
        if (this.isLoggedIn) {
            this.userProfile = await this.userService.loadUserProfile();

            if (!this.selectedTenant) {
                this.selectCompany();
            } else {
                // Broadcast the selected company to other components.
                this.dataShareService.broadcastSelectedCompanyObservable(
                    this.selectedTenant,
                );
            }
        }

        console.log(this);
    }

    selectCompany(): void {
        const dialogRef = this.matDialog.open(SelectCompanyDialog, {
            width: '250px',
        });

        dialogRef.afterClosed().subscribe((result) => {
            console.log(
                `The SelectCompanyDialog was closed, result = ${result}`,
            );
            this.checkSelectTenantResult(result);
        });
    }

    private checkSelectTenantResult(result: string | undefined) {
        if (!result) {
            if (!this.selectedTenant) {
                // If we don't already have a tenant, then as the user again to select.
                this.selectCompany();
            }
        } else {
            this.updateSelectedCompany(result);
        }
    }

    private updateSelectedCompany(result: string) {
        this.dataShareService.broadcastSelectedCompanyObservable(result);
        this.selectedTenant = result;
        localStorage.setItem(
            LocalStorageConstants.SELECTED_TENANT,
            this.selectedTenant,
        );
    }
    public getRuntimeEnv(): string {
        return GlobalVars.appConfig.environment;
    }

    public isProdEnvironment(): boolean {
        return GlobalVars.appConfig.environment === RuntimeEnvironment.prod;
    }

    logout() {
        this.keycloakService
            .logout('http://localhost:4200/')
            .then((next) => {
                console.log('logged out');
            })
            .catch((err) => {
                console.error('Err Logging out', err);
            });
    }
}
