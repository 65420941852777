import { Routes } from '@angular/router';
import { AuthGuard } from './security/keycloak.guard';

export const routes: Routes = [
    { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
    {
        path: 'dashboard',
        loadComponent: () =>
            import('./components/dashboard/dashboard.component').then(
                (m) => m.DashboardComponent
            ),
        canActivate: [AuthGuard],
        data: {
            roles: ['ROLE_USER'],
        },
    },
    {
        path: 'ama',
        loadComponent: () =>
            import('./components/ama/ama.component').then(
                (m) => m.AmaComponent
            ),
        canActivate: [AuthGuard],
        data: {
            roles: ['ROLE_USER'],
        },
    },
    {
        path: 'manageFiles',
        loadComponent: () =>
            import('./components/upload-files/upload-files.component').then(
                (m) => m.UploadFilesComponent
            ),
        canActivate: [AuthGuard],
        data: {
            roles: ['ROLE_USER'],
        },
    },
    {
        path: 'audit',
        loadComponent: () =>
            import('./components/audit-component/audit.component').then(
                (m) => m.AuditComponent
            ),
        canActivate: [AuthGuard],
        data: {
            roles: ['ROLE_ADMIN'],
        },
    },
];
